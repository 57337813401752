html {
  font-size: 14px;
}
body {
  margin: 0;
  font-family: 'IBM Plex Mono', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header, h1, h2, h3, h4, h5 {
  font-family: 'IBM Plex Mono', serif;
  font-weight: 700;
}